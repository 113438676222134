import React, { useContext, useEffect, useState, useRef } from 'react';
import { css, cx } from 'linaria';
import { useMutation } from '@apollo/react-hooks';
import { ReactComponent as CartIcon } from '../../assets/svg/Cart.svg';
import { useStockStatus } from '@jetshop/ui/Stock/StockStatusContainer';
import { useProductValidationMessage } from '../../pages/ProductPage/useProductValidationMessage';

import t from '@jetshop/intl';
import { CartIdContext } from '@jetshop/core/components/Cart/CartIdContext';
import { addToCartSuccess } from '@jetshop/core/components/Mutation/AddToCart/addToCartUtils';
import { useNotification } from '@jetshop/core/components/Notifications';

import ProductToastWrapper from '../../pages/ProductPage/AddToCart/ProductToast';
import addToCartMutation from '../../gql/mutations/addToCart.gql';
import { theme } from '../Theme';
import { uniqueId } from 'lodash';
import useArticleInfo from '../../components/HelloRetail/HelloRetailProductInformation';
import { useTracker } from '@jetshop/core/analytics/Analytics';
import { trackProductEvent } from '@jetshop/core/analytics/tracking';

const addButton = css`
  background: ${theme.colorScheme.black};
  color: ${theme.colorScheme.white};
  display: inline-block;
  border-radius: 2em;
  text-align: center;
  padding: 0.5em;
  height: 2em;
  width: 2em;
  position: relative;
  svg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 3px 3px;
  }
  .button-text {
    position: absolute;
    left: -999rem;
  }
  &[disabled] {
    opacity: 0.4;
  }
`;

/**
 *
 * @param {object} props
 * @param {import('@jetshop/core/hooks/ProductList').ProductListProduct} props.product
 */
export function AddToCart({ product, className, variant, notificationId }) {
  const { cartId, setCartId } = useContext(CartIdContext);
  const stockStatus = useStockStatus(product);
  const relatedProducts = product.relatedProducts;
  const outOfStockNotify = stockStatus.status === 'notifyWhenBack';
  const { hasVariants } = product;
  const { validationMessage } = useProductValidationMessage({
    missingOptions: true,
    variantValidation: true,
    hasVariants,
    stockStatus
  });

  const articleNumber = variant?.articleNumber || product.articleNumber;
  const price = {
    price: product.variant?.price || product.price,
    previousPrice: product.variant?.previousPrice || product.previousPrice
  };
  const track = useTracker();

  const [trigger, dismiss] = useNotification();
  const [add] = useMutation(addToCartMutation, {
    variables: {
      input: {
        cartId,
        articleNumber,
        quantity: 1
      }
    },
    onCompleted: data => {
      const id = uniqueId();
      trigger(
        <ProductToastWrapper
          selectedVariation={variant}
          product={product}
          quantity={1}
          price={price}
          relatedProducts={relatedProducts}
          dismiss={dismiss}
          notificationId={id}
        />,
        { id, type: 'add-to-cart', autoCloseAfter: 0 }
      );

      addToCartSuccess({ cartId, setCartId })({ data });
      track(
        trackProductEvent({
          action: 'add',
          product,
          selectedVariant: variant
        })
      );
    }
  });
  const [articleNumberHR, setArticleNumber] = useState(null);
  const [variantHR, setVariantNumber] = useState(null);
  const { loading, error, data } = useArticleInfo(articleNumberHR);
  const [fetchedProduct, setFetchedProduct] = useState(null);
  const [fetchedVariant, setFetchedVariant] = useState(null);
  const isProcessingRef = useRef(false); // Use a ref to track processing state
  const manualTriggerRef = useRef(false);
  const [addMutation] = useMutation(addToCartMutation, {
    onCompleted: data => {
      const id = uniqueId();
      trigger(
        <ProductToastWrapper
          selectedVariation={fetchedVariant}
          product={fetchedProduct}
          quantity={1}
          dismiss={dismiss}
          notificationId={id}
        />,
        { id, type: 'add-to-cart', autoCloseAfter: 0 }
      );
      addToCartSuccess({ cartId, setCartId })({ data });
      track(
        trackProductEvent({
          action: 'add',
          product,
          selectedVariant: fetchedVariant
        })
      );
      setFetchedVariant(null);
      setFetchedProduct(null);
      setVariantNumber(null);
      isProcessingRef.current = false; // Reset the processing state
      manualTriggerRef.current = false; // Reset the manual state
    },
    onError: error => {
      console.error('Error executing addToCartHR mutation:', error);
      isProcessingRef.current = false; // Reset the processing state
      manualTriggerRef.current = false; // Reset the manual state
    }
  });

  const addToCartHR = (articleHR, variantNumberHR) => {
    if (isProcessingRef.current) return; // Prevent re-triggering if already processing
    isProcessingRef.current = true; // Set the processing state
    manualTriggerRef.current = true; // Mark as manually triggered
    setVariantNumber(variantNumberHR);
    setArticleNumber(articleHR);
  };

  useEffect(() => {
    if (
      manualTriggerRef.current &&
      articleNumberHR &&
      !loading &&
      !error &&
      data
    ) {
      const article = data.products[0];
      setFetchedProduct(article);
      if (article.hasVariants) {
        var indexOfVariant = 0;
        for (
          var variantIndex = 0;
          variantIndex < article.variants.values.length;
          variantIndex++
        ) {
          if (variantHR === article.variants.values[variantIndex].articleNumber)
            indexOfVariant = variantIndex;
        }
        const variantsHR = article.variants.values[indexOfVariant];
        setFetchedVariant(variantsHR);
      }
      // Proceed with the mutation using the fetched article information
      if (article.hasVariants) {
        addMutation({
          variables: {
            input: {
              cartId,
              articleNumber: variantHR,
              quantity: 1
            }
          }
        });
      } else {
        addMutation({
          variables: {
            input: {
              cartId,
              articleNumber: article.articleNumber,
              quantity: 1
            }
          }
        });
      }

      setArticleNumber(null);
      setVariantNumber(null);
    }
  }, [articleNumberHR, loading, error, data, addMutation, cartId]);

  useEffect(() => {
    // Attach the addToCartHR function to the global window object
    window.addToCartHR = addToCartHR;
    return () => {
      window.addToCartHR = null; // Clean up to prevent lingering references
    };
  }, [addToCartHR]);

  return (
    <button
      className={cx(addButton, className)}
      onClick={() => {
        notificationId && dismiss(notificationId);
        add();
      }}
      disabled={!!validationMessage || outOfStockNotify}
    >
      <span className="button-text">{t('Add to cart')}</span>
      <CartIcon />
    </button>
  );
}
